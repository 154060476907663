/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react'
import Avatar from '../../Assets/Images/avatar-default.svg'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { useController } from 'react-hook-form'
import { debounce } from 'lodash'
import CropperImage from './CropperImage'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { fileValidator } from '../Helpers/GenericFunctions'
import { toastSweetAlert } from '../Helpers/ToastSweetAlert'
import { UPDATE_AVATAR } from '../../graphql/Global/uploadFiles'
import { useMutation } from '@apollo/client'
import { GET_USER_BY_ID } from '../../graphql/Catalog/Users/user'

export const InputController = ({
  valueManual,
  label,
  name,
  control,
  rows = 3,
  options = '',
  inputType = 'text',
  userId = null,
  isMulti = false,
  closeOnSelect = true,
  validateFormat = 'image',
  validateFormatMsg = 'Elija uno de los formatos sugeridos',
  formatAccept = 'image/*',
  previewPic = Avatar,
  addClass = '',
  readOnly = false,
  disabled = false,
  dataToPersist,
  changeAction = () => {},
  blurAction = () => {},
  debounceTime = 500,
  limitCharacters = false,
  placeHolderChoosen = 'Selecciona una opción',
  customHandleChange,
  menuPosition = 'absolute',
  ...props
}) => {
  const [cropper, setCropper] = useState(null)
  const [image, setImage] = useState(Avatar)
  const [result, setResult] = useState(false)
  const [updateAvatar] = useMutation(UPDATE_AVATAR, {
    refetchQueries: [
      GET_USER_BY_ID,
      {
        variables: {
          getUserByIdId: parseInt(userId),
        },
      },
    ],
  })
  const MySwal = withReactContent(Swal)
  const {
    field: { onChange, value },
    fieldState: { invalid, error },
  } = useController({ control, name, defaultValue: '' })

  const animatedComponents = makeAnimated()

  const handleChange = (e, dataToPersist) => {
    let value = limitCharacters
      ? handleLimitCharacters(e.target.value, limitCharacters)
      : e.target.value
    onChange(value, dataToPersist)
    doAction(e, dataToPersist)
  }

  const handleLimitCharacters = (value, limit) => {
    if (value.length > limit) {
      value = value.slice(0, limit)
    }
    return value
  }

  const handleChangeChoosen = (e) => {
    if (isMulti) onChange(e)
    else onChange(e.value)
    doAction(e)
    if (customHandleChange) customHandleChange(e)
  }

  const doAction = useRef(
    debounce((e, dataToPersist) => {
      changeAction(e, dataToPersist)
    }, debounceTime)
  ).current

  const handleClickInput = (name) => {
    const element = document.getElementById(name)
    element.click()
  }

  const handleChangeImage = async (e, validateFormat, autoUpdate = false) => {
    if (!e.target.files[0]) {
      return
    }
    const validate = await fileValidator(e.target.files[0], validateFormat)
    if (validate) {
      changeAction(e)
      const isComplete = await handleCropImage(e.target.files[0])
      if (isComplete) {
        setTimeout(async function () {
          let res, img

          img = localStorage.getItem('usersImage')
          if (img) res = await getFileFromUrl(img, '.jpg')
          else res = e.target.files[0]

          onChange(res)
          if (autoUpdate) {
            await updateAvatar({
              variables: {
                idUser: parseInt(userId),
                avatar: res,
              },
            })
          }
        }, 350)
      }
    } else
      return toastSweetAlert({
        mode: 'error',
        message: validateFormatMsg,
      })
  }

  const handleChangeFile = async (e, validateFormat) => {
    const file = e.target.files[0]
    if (!file) return
    const validate = await fileValidator(e.target.files[0], validateFormat)
    if (validate) {
      changeAction(e)
      onChange(e)
    } else {
      onChange('')
      return toastSweetAlert({
        mode: 'error',
        message: validateFormatMsg,
      })
    }
  }

  const handleCheck = (e) => {
    onChange(e.target.checked)
    doAction(e)
  }

  const handleCropImage = async (file) => {
    if (file) {
      return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = async () => {
          await MySwal.fire({
            title: 'Selecciona la fracción que deseas utilizar',
            allowOutsideClick: false,
            html: (
              <CropperImage _image={reader.result} setCropper={setCropper} />
            ),
            showDenyButton: true,
            focusConfirm: false,
            denyButtonText: '<i class="fas fa-times"> Cancelar</i>',
            confirmButtonText: '<i class="fas fa-check"> Finalizar</i>',
            customClass: {
              confirmButton: 'btn btn-sm btn-accept',
              denyButton: 'btn btn-sm btn-danger',
              icon: 'no-border',
            },
          }).then((result) => {
            setResult(result.isConfirmed)
            return resolve(result.isConfirmed)
          })
        }
        reader.readAsDataURL(file)
      })
    }
  }

  const getFileFromUrl = async (url, defaultType) => {
    const response = await fetch(url)
    const data = await response.blob()
    return new File([data], 'file', {
      type: response.headers.get('content-type') || defaultType,
    })
  }

  //CONTROl THE SET OF THE CROPPED IMAGE
  useEffect(() => {
    if (result) {
      localStorage.setItem('usersImage', cropper?.toDataURL())
      setImage(cropper?.toDataURL())
      setResult(false)
    }
  }, [result, cropper, image])

  //SETIMAGE PREVIEW WHEN EXISTS
  useEffect(() => {
    if (!cropper) {
      setImage(previewPic)
    }
  }, [image, previewPic, cropper])

  return (
    <>
      {inputType === 'text' && (
        <>
          {label && (
            <label>
              <b>{label}</b>
            </label>
          )}
          <input
            {...props}
            onChange={(e) => handleChange(e, dataToPersist)}
            value={valueManual ? valueManual : value ? value : ''}
            onBlur={blurAction}
            className={`form-control rounded-2 ${
              invalid && 'is-invalid'
            } ${addClass}`}
            readOnly={readOnly}
            disabled={disabled}
          />
          <span className="error invalid-feedback">{error?.message}</span>
        </>
      )}
      {inputType === 'textarea' && (
        <>
          {label && (
            <label>
              <b>{label}</b>
            </label>
          )}
          <textarea
            {...props}
            onChange={(e) => handleChange(e)}
            value={value ? value : ''}
            rows={rows}
            onBlur={blurAction}
            className={`form-control rounded-2 ${
              invalid && 'is-invalid'
            } ${addClass}`}
            readOnly={readOnly}
            disabled={disabled}
          />
          <span className="error invalid-feedback">{error?.message}</span>
        </>
      )}

      {inputType === 'select' && (
        <>
          {label && (
            <label>
              <b>{label}</b>
            </label>
          )}
          <select
            {...props}
            onChange={(e) => handleChange(e)}
            value={value ? value : ''}
            rows={rows}
            onBlur={blurAction}
            className={`form-control rounded-2 ${
              invalid && 'is-invalid'
            } ${addClass}`}
            readOnly={readOnly}
            disabled={disabled}
          >
            <option disabled hidden value="">
              Seleccione una opción
            </option>
            <>
              {options.length > 0 ? (
                <> {options}</>
              ) : (
                <option value="" disabled>
                  No hay datos registrados
                </option>
              )}
            </>
          </select>
          <span className="error invalid-feedback">{error?.message}</span>
        </>
      )}
      {inputType === 'choosen' && (
        <>
          {label && (
            <label>
              <b>{label}</b>
            </label>
          )}
          <Select
            {...props}
            menuPosition={menuPosition}
            closeMenuOnSelect={closeOnSelect}
            components={animatedComponents}
            name={name}
            placeholder={placeHolderChoosen}
            NoOptionsMessage="No hay datos registrados"
            isMulti={isMulti}
            control={control}
            options={options}
            onBlur={blurAction}
            onChange={(e) => handleChangeChoosen(e)}
            className={`${invalid && 'is-invalid'} ${addClass}`}
            value={
              valueManual !== ''
                ? isMulti
                  ? value
                  : options?.find((option) => {
                      if (parseInt(option.value) === parseInt(value)) {
                        return option
                      } else if (option.label === value) {
                        return option
                      }
                      return null
                    })
                : null
            }
            styles={{
              control: (base) => ({
                ...base,
                borderColor: invalid ? '#dc3545' : '#ced4da',
                ':hover': { borderColor: invalid ? '#dc3545' : '#ced4da' },
              }),
            }}
            readOnly={readOnly}
            isDisabled={disabled}
          />
          <span className="error invalid-feedback">{error?.message}</span>
        </>
      )}

      {inputType === 'image' && (
        <div className="container-upload">
          <div className="avatar-upload">
            <input
              {...props}
              id={name}
              type="file"
              hidden
              defaultValue={value}
              onChange={(e) => handleChangeImage(e, validateFormat)}
              onBlur={blurAction}
              accept={formatAccept}
              control={control}
              readOnly={readOnly}
              disabled={disabled}
            />
            <label
              className="btn btn-danger circle-btn"
              htmlFor="imageUpload"
              onClick={() => handleClickInput(name)}
            >
              <i
                className={`fas fa-${
                  cropper || image ? 'pencil-alt' : 'plus'
                } `}
              ></i>
            </label>
          </div>
          <div className="avatar-preview">
            <img src={cropper || image ? image : Avatar} alt="avatar-upload" />
          </div>
          <span className="error">{error?.message}</span>
        </div>
      )}

      {inputType === 'imageAutoSend' && (
        <div className="container-upload">
          <div className="avatar-upload">
            <input
              {...props}
              id={name}
              type="file"
              hidden
              defaultValue={value}
              onChange={(e) => handleChangeImage(e, validateFormat, true)}
              onBlur={blurAction}
              accept={formatAccept}
              control={control}
              readOnly={readOnly}
              disabled={disabled}
            />
            <label
              className="btn btn-danger circle-btn"
              htmlFor="imageUpload"
              onClick={() => handleClickInput(name)}
            >
              <i
                className={`fas fa-${
                  cropper || image ? 'pencil-alt' : 'plus'
                } `}
              ></i>
            </label>
          </div>
          <div className="avatar-preview">
            <img src={cropper || image ? image : Avatar} alt="avatar-upload" />
          </div>
          <span className="error">{error?.message}</span>
        </div>
      )}

      {inputType === 'file' && (
        <>
          {label && (
            <label>
              <b>{label}</b>
            </label>
          )}
          <input
            {...props}
            type="file"
            defaultValue={value}
            onChange={(e) => handleChangeFile(e, validateFormat)}
            accept={formatAccept}
            onBlur={blurAction}
            className={`form-control rounded-2 ${
              invalid && 'is-invalid'
            } ${addClass}`}
            readOnly={readOnly}
            disabled={disabled}
          />
          <span className="error invalid-feedback">{error?.message}</span>
        </>
      )}
      {inputType === 'checkbox' && (
        <>
          {label && (
            <label>
              <b>{label}</b>
            </label>
          )}
          <input
            {...props}
            type="checkbox"
            checked={value}
            onChange={(e) => handleCheck(e)}
            onBlur={blurAction}
            control={control}
            className={`form-control rounded-2 ${
              invalid && 'is-invalid'
            } ${addClass}`}
            readOnly={readOnly}
            disabled={disabled}
          />
          <span className="error invalid-feedback">{error?.message}</span>
        </>
      )}
    </>
  )
}
export default InputController
